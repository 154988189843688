.image {
	position: relative;
	width: 100%;
	height: 720px;
	margin-top: -110px;

}

.gain_center{
  transition: 1s; /* Время эффекта */
  /* text-align: ; */
}
.gain_center:hover {
  transform: scale(1.2); /* Увеличиваем масштаб */
}
