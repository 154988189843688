.header {
	position: relative;
	width: 100%;
	height: 420px;
	background:
		linear-gradient(rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0.8)),
		url('https://cdn.informator.ua/@prod/_1920/media/ua/2022/12/01/6388ea05476817.62306627.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: top;
}

.text {
  font-size: 90px;
}

header img:hover {
	/* Start the shake animation and make the animation last for 0.5 seconds */
	animation: shake 1.9s;

	/* When the animation is finished, start again */
	animation-iteration-count: infinite;
}

@keyframes shake {
	0% { transform: translate(1px, 1px) rotate(0deg); }
	10% { transform: translate(-1px, -2px) rotate(-1deg); }
	20% { transform: translate(-3px, 0px) rotate(1deg); }
	30% { transform: translate(3px, 2px) rotate(0deg); }
	40% { transform: translate(1px, -1px) rotate(1deg); }
	50% { transform: translate(-1px, 2px) rotate(-1deg); }
	60% { transform: translate(-3px, 1px) rotate(0deg); }
	70% { transform: translate(3px, 1px) rotate(-1deg); }
	80% { transform: translate(-1px, -1px) rotate(1deg); }
	90% { transform: translate(1px, 2px) rotate(0deg); }
	100% { transform: translate(1px, -2px) rotate(-1deg); }
}
