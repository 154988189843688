.footer{
	position: relative;
	width: 100%;
	/* height: 420px; */
	background:
		linear-gradient(rgba(255, 255, 255, 0.7), rgba(108, 107, 107, 0.8)),
		url('https://cdn.informator.ua/@prod/_1920/media/ua/2022/12/01/6388ea05476817.62306627.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: top;

}

.viber{
  color: rgb(130, 23, 238);
}
.insta{
  color: rgb(235, 34, 111);
}

.gain_center{
  transition: 1s; /* Время эффекта */
  text-align: center;
}
.gain_center:hover {
  transform: scale(1.2); /* Увеличиваем масштаб */
}
